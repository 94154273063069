import { takeLatest, takeEvery } from "redux-saga/effects";
import { GET_PAYMENT } from "../invoice/redux/actionTypes";
import { getPaymentSaga } from "../invoice/redux/saga";
import { LOGIN_USER } from "../login/redux/actionTypes";
import { LoginSaga } from "../login/redux/saga";
import { CREATE_PAYMENT, GET_APPLICATION } from "../portal/applications/redux/actionTypes";
import { getAppsSaga, paymentIntentSaga } from "../portal/applications/redux/saga";
import { REGISTER_APP, SET_APP } from "../portal/components/redux/actionTypes";
import { AddAppSaga } from "../portal/components/redux/saga";
import { GET_TRANSACTIONS } from "../portal/payments/redux/actionTypes";
import { REGISTER_USER } from "../register/redux/actionTypes";
import { AddUserSaga } from "../register/redux/saga";
import {getTransactionsSaga} from '../portal/payments/redux/saga';

// import { signupSaga, loginSaga, logOutSaga, readUsersSaga, deleteUsersSaga } from "./Auth/saga";


export default function* rootSaga() {
  //signup
  yield takeLatest(REGISTER_USER, AddUserSaga);

  // //login
  yield takeLatest(LOGIN_USER, LoginSaga);
  //AddAppSaga
  yield takeLatest(REGISTER_APP, AddAppSaga);

  //getAppsSaga
  yield takeLatest(GET_APPLICATION, getAppsSaga);

  //paymentIntentSaga

  yield takeLatest(CREATE_PAYMENT, paymentIntentSaga);

  //
  yield takeLatest(GET_PAYMENT, getPaymentSaga);

  yield takeLatest(GET_TRANSACTIONS, getTransactionsSaga);


}
