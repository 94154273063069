import axios from 'axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { endPoint } from '../../redux/rootApi';


//public page page

export async function getPayment(data) {
  return await axios.get(`${endPoint}pay/${data}`
  )
}


