import { combineReducers } from "redux";
import loginReducer from "../login/redux/reducers";
import appReducer from "../portal/components/redux/reducers";

// import { persistStore, persistReducer } from 'redux-persist';
import registerReducer from "../register/redux/reducers";
import getapplicationsReducer from "../portal/applications/redux/reducers";
import getPaymentReducer from "../invoice/redux/reducers";
import getTransactionsReducer from "../portal/payments/redux/reducers";


//

const reducers = {
  register : registerReducer,
  login: loginReducer,
  app: appReducer,
  applications: getapplicationsReducer,
  getpayment: getPaymentReducer,
  transactions: getTransactionsReducer
};

const rootReducer = combineReducers(reducers);
export default rootReducer;
