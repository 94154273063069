import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './dashboard/listItems';
import { Button, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Slide from '@material-ui/core/Slide';
import { ToastContainer } from 'react-toastify';
import { Route, Switch } from 'react-router';
import Dashboard from "./dashboard/Dashboard";
import Applications from './applications/Applications';
import Payments from './payments/Payments';
import { TrainRounded } from '@material-ui/icons';
import sidebar from '../images/sidebar.png';
import { useLocation } from 'react-router-dom'
import selectApp from './selectApp/selectApp';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.little.bz/">
        Little.bz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    backgroundColor: '#f5f7fe',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    color: 'secondary',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    backgroundColor: '#f5f7fe',
    backgroundImage: `url(${sidebar})`,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
 
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    marginLeft: "240px"
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  appBard: {
    position: 'relative',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Portal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [opend, setOpenDialogue] = React.useState(false);
  const location = useLocation();
  // alert(location.pathname);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleClickOpen = () => {
    setOpenDialogue(true);
  };

  const handleClose = () => {
    setOpenDialogue(false);
  };
  
 let su = false;
  const routes = [
    {id: '1', su: false, route: <Route path="/app/portal" component={Dashboard} />},
    {id: '2', su: false, route: <Route path="/app/applications" component={Applications} />},
    {id: '3', su: false, route: <Route path="/app/payments" component={Payments} />},
    {id: '4', su: false, route: <Route path="/app/select_app" component={selectApp} />}

  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar  position="fixed" style={{ backgroundColor: "#3174ff" }} className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />

          </IconButton>

          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
           {!open && ("Little Pay")} 
          </Typography>
          <Button variant="outlined" disableElevation color="primary" size="small" style={{ color: "#ffffff"}} onClick={ e  => (props.history.push('/login'))}>
            Logout
            </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
       
        <div className={classes.toolbarIcon} >
        <img src="https://wallet-delta.vercel.app/static/media/logo.7584297e.png" style={{width:"50%", marginRight: '50px'}}/>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        
        <Divider style={{ marginBottom: '20px'}}/>
        <List component="nav">{mainListItems(location.pathname)}</List>
        <Divider style={{marginTop: "20px", marginBottom: "20px"}}/>
        <List>{secondaryListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <ToastContainer />
            <Switch>
              {/* routes */}
              {!su ? routes.filter(item => !item.su).map(route => (
                  route.route
                )) : routes.filter(data => !data.isBank).map(route => (
                 route.route
                ))}
            </Switch>
      </main>
    </div>
  );
}

