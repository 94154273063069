import { call, put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { loginUserFailed, loginUserSuccess } from "./actions";
import { loginCall } from "./api";



export function* LoginSaga(action) {
    try {
      const res = yield call(loginCall, action.data);
      console.log("add user console",res);
      yield put(loginUserSuccess("res.data"));
      toast("User has been logined");
      localStorage.setItem('timestamp', res.data.data.token);
      action.history.push('/app');
    } catch (err) {
      yield put(loginUserFailed(err));
      console.log("Error of the users add", err);
      toast("Invalid User Data");
    //   alert("failed");
    }
  }
  