import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Invoice from "./invoice/Invoice";
import Landing from "./landing/Landing";
// components
//import Layout from "./Layout";
// pages
//import Error from "../pages/error";
import Login from "./login/Login";
import Portal from "./portal/Portal";
import Register from "./register/Register";

//The build

export default function App() {

  // redux variable stuff

  // var { isAuthenticated } = useUserState();
  // let token = useSelector(state => state.login.token);

  return (
    <HashRouter >
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/portal" />}
        />

        <PrivateRoute path="/app" component={Portal} />
        <Route path="/invoice/:id" component={Invoice} />
        <Route path="/home" component={Landing} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Register} />

        {/* <Route component={Error} /> */}
      </Switch>
    </HashRouter>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem("timestamp") ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  // function PublicRoute({ component, ...rest }) {
  //   return (
  //     <Route
  //       {...rest}
  //       render={props =>
  //         token ? (
  //           <Redirect
  //             to={{
  //               pathname: "/",
  //             }}
  //           />
  //         ) : (
  //           React.createElement(component, props)
  //         )
  //       }
  //     />
  //   );
  // }

}
