
import {

   REGISTER_USER,REGISTER_USER_FAILED,REGISTER_USER_SUCCESS,SET_USER
} from "./actionTypes";
    

//initial state
    const InitialState = {
      userData: "Hello world",
      userDetails: {
        FullName: "",
        Email: "",
        Password: ""
      },
      loading: false,
      error: null,
      success: false,
    };
    
    export default function registerReducer (state = InitialState, action) {
      
      switch (action.type) {
        //create agents
      
        case SET_USER:
          state.userDetails[action.field] = action.data;
          let userr = state.userDetails;
          return {
            ...state,
            userDetails: userr,
            loading: false,
            error: null,
            success: false,
            
          };

          case REGISTER_USER:
            return {
              ...state,
              loading: true,
              error: null
            };

            case REGISTER_USER_SUCCESS:
              return {
                ...state,
                loading: false,
                success: true,
                error: null
              };
              case REGISTER_USER_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                error: action.response
              };
        default:
          return state;
      }
    }
    





// registerReducer