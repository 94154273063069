import axios from 'axios';
import FormData from 'form-data';
import { endPoint } from '../../../redux/rootApi';


//loans
// name(pin):"dsdsd"00000
// description(pin):"dsds"0000
// website(pin):"dsdsd"00000
// business_name(pin):"dsds"000
// address(pin):"dsdsd"000
// color(pin):"#3174ff"000000
// bank_name(pin):"dsdsd"000
// branch(pin):"dsdsd"0000
// bank_account(pin):"dsdsd"

export async function appsCall(data) {
  const formData = new FormData();
  //name.,description,privacyUrl,settings[color],logo,
  formData.append('name',data.name);
  formData.append('description',data.description);
  formData.append('privacyUrl',data.website);
  formData.append('settings[color]',data.color);
  formData.append('settings[business_name]',data.business_name);
  formData.append('settings[address]',data.address);
  formData.append('settings[bank_name]',data.bank_name);
  formData.append('settings[branch]',data.branch);
  formData.append('settings[bank_account]',data.bank_account);
  formData.append('logo',data.logo[0]);
  
  return await axios.post(`${endPoint}apps`, 
  formData,
  {
    headers: {
       'Authorization' : `Bearer ${localStorage.getItem("timestamp")}`,
        'Content-Type': 'multipart/form-data',
       
    }
}
  )
}
