import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  GET_APPLICATION,GET_APPLICATION_FAILED,GET_APPLICATION_SUCCESS
  } from "./actionTypes";

    

//initial state
    const InitialState = {
      applications: [],
      loading: false,
      error: null,
      success: false,
      transactionId: ""
    };
    
    export default function getapplicationsReducer (state = InitialState, action) {
      
      switch (action.type) {
        //create agents

          case GET_APPLICATION:
            return {
              ...state,
              loading: true,
              error: null
            };

            case GET_APPLICATION_SUCCESS:
              return {
                ...state,
                applications: action.response,
                loading: false,
                success: true,
                error: null
              };
              case GET_APPLICATION_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                error: action.response
              };

              case CREATE_PAYMENT:
              return {
                ...state,
                loading: true,
                success: false,
                // transactionId: action.response
              };

              case CREATE_PAYMENT_SUCCESS:
              return {
                ...state,
                loading: false,
                success: false,
                transactionId: action.response
              };
              case CREATE_PAYMENT_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                // transactionId: action.response
              };
              
        default:
          return state;
      }
    }
    





// registerReducer