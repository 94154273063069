import { call, put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { getTransactionsFailed,getTransactionsSuccess} from "./actions";
import { getTransactionsCall } from "./api";
import { endPoint } from '../../../redux/rootApi';

export function* getTransactionsSaga(action) {
    try {
      const res = yield call(getTransactionsCall , action.data);
      console.log("get transactions user console*******", res.data);
     yield put(getTransactionsSuccess(res.data.data.rows));
      // toast("success");
      //alert("success");
    } catch (err) {
      yield put(getTransactionsFailed(err));
      console.log("Error of the transactions fetch", err.response.data.message);
      // toast("Connection Error transactions");
    //   alert("failed");
    }
  }
