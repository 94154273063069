import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { NavLink,useLocation  } from 'react-router-dom';

// https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNFqXfTse9_KJ-Rlh-_9WyjF6eWmdinvGSw&usqp=CAU

export  const mainListItems = (text) => (
  <div>
     <NavLink to="/app" style={{ textDecoration: 'none', color: '#000000' }}>
    <ListItem button selected={text === '/app/portal'}>
      <ListItemIcon>
        <img src= 'http://cmo-togo.com/wp-content/uploads/2017/11/dashboard_icon6.png' style={{width: '40px'}}/>
      </ListItemIcon>
      <ListItemText secondary={"Dashboard"} style={{fontSize: 5}}/>
    </ListItem>
    </NavLink>
    
    <NavLink to="/app/payments" style={{ textDecoration: 'none', color: '#000000' }}>
    <ListItem button selected={text === '/app/payments'}>
      <ListItemIcon>
      <img src= 'https://aux.iconspalace.com/uploads/card-payment-icon-256.png' style={{width: '40px'}}/>
      </ListItemIcon>
      <ListItemText secondary="Payments" />
    </ListItem>
    </NavLink>

    <NavLink to="/app/applications" style={{ textDecoration: 'none', color: '#000000' }}>
    <ListItem button selected={text === '/app/applications'}>
      <ListItemIcon>
      <img src= 'https://www.shareicon.net/data/2017/02/09/878592_folder_512x512.png' style={{width: '40px'}}/>
      </ListItemIcon>
      <ListItemText secondary="My Applications" />
    </ListItem>
    </NavLink>

    {/* <ListItem button>
      <ListItemIcon>
        <LayersIcon style={{ color: "#A9A9A9" }}/>
      </ListItemIcon>
      <ListItemText primary="Profile" />https://www.arcadophta.com/wp-content/uploads/2016/01/save-time-operation.png
    </ListItem>

    <ListItem button>
      <ListItemIcon>
        <LayersIcon style={{ color: "#A9A9A9" }}/>
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItem> */}
  </div>
);

export const secondaryListItems = (
  <div>
    {/* <ListSubheader inset>Saved reports</ListSubheader> */}
    <a href="https://www.little.bz/ke/tnc.php" target="blank">
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText secondary="Terms and Conditions" />
    </ListItem>
    </a>
  
    {/* <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="This Year" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem> */}
  </div>
);

