import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField } from '@material-ui/core';
import wallet from "../../images/wallet.png";
import { SketchPicker } from 'react-color'
import { DropzoneArea } from 'material-ui-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { registerApp, setApp } from './redux/actions';
import PropagateLoader from "react-spinners/PropagateLoader";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function AppDetails() {
  const dispatch = useDispatch();
  const app = useSelector(state => state.app.app);
  
    return (
        <div>
      <Typography variant="body2" color="textSecondary" align="center">
      This is the name of the application instance for receiving payments
      </Typography>
      <Grid container spacing={0}>
      <Grid item xs={12}>
      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("name" ,e.target.value))}
          defaultValue={app.name }
          required
          fullWidth
          name="password"
          label={"App Name"}
          id="password"
          />
      </Grid>

      <Grid item xs={12}>
      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("description" ,e.target.value))}  
          defaultValue={app.description }       
           required
          fullWidth
          name="password"
          label={"Description"}
          multiline
          id="password"
          />
      </Grid>

      <Grid item xs={12}>
      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("website" ,e.target.value))}   
          defaultValue={app.website }
          required
          fullWidth
          name="password"
          label={"Website URL"}
          id="password"
          />
      </Grid>
  </Grid>
  </div>
    );
  }


  function AppSettings() {
    let [myColor, pickedColor] = useState('');
    const [background, setbackground] = useState({hex: '#3174ff'});
    const [TheColor, setTheColor] = useState("#3174ff");
    const dispatch = useDispatch();
    const app = useSelector(state => state.app.app)

    const setColorCode = (color)=> {
      setbackground(color);
      setTheColor(color.hex);
      console.log("the color be", color);
    }
    return (
        <div>
      <Typography variant="body2" color="textSecondary" align="center">
      This are the details your customers will see when you send them an invoice or when they click the payment button in your website
      </Typography>
      <Grid container spacing={0}>
      <Grid item xs={12}>

      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("business_name" ,e.target.value))}    
          required
          defaultValue={app.business_name}
          fullWidth
          name="password"
          label={"Business/Company Name"}
          id="password"
          />
      </Grid>

      <Grid item xs={12}>
      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("address" ,e.target.value))}    
          required
          fullWidth
          defaultValue={app.address}
          name="password"
          label={"Physical Address"}
          multiline
          id="password"
          />
      </Grid>
      <Grid item xs={12} style={{height: "50px"}}>
        
      </Grid>
  </Grid>
  </div>
    );
  }


  function AppSettlement() {
    const [background, setbackground] = useState({hex: '#3174ff'});
    const dispatch = useDispatch();
    const app = useSelector(state => state.app.app)
    
    return (
        <div>
      <Typography variant="body2" color="textSecondary" align="center">
          Bank Account details where all payments will be received
      </Typography>
      <Grid container spacing={0}>
      <Grid item xs={12}>

      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("bank_name" ,e.target.value))}    
          defaultValue={app.bank_name}
          required
          fullWidth
          name="password"
          label={"Bank Name"}
          id="password"
          />
      </Grid>

      <Grid item xs={12}>
      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("branch" ,e.target.value))}    
          defaultValue={app.branch}

          required
          fullWidth
          name="password"
          label={"Bank Branch"}
          multiline
          id="password"
          />
      </Grid>

      <Grid item xs={12}>
      <TextField
          variant="outlined"
          margin="normal"
          onChange={e => dispatch(setApp("bank_account" ,e.target.value))}    
          defaultValue={app.bank_account}
          required
          fullWidth
          name="password"
          label={"Bank Account Number"}
          id="password"
          />
      </Grid>
      <Grid item xs={12} style={{height: "50px"}}>
        
      </Grid>

  </Grid>
  </div>
    );
  }

  function AppLogo() {
    
    const [files, setfiles] = useState([]);
    const dispatch = useDispatch();
    const app = useSelector(state => state.app.app);

    return (
        <div>
      <Typography variant="body2" color="textSecondary" align="center">
          Upload the logo for that the customers will see when making a payment 
      </Typography>
      <Grid container spacing={0}>
     
      <Grid item xs={12} >
      <DropzoneArea
        // onChange={setfiles}
        acceptedFiles={['image/*']}
        filesLimit={1}
        onChange={(files) => dispatch(setApp("logo" ,files))}
        showAlerts={false}
        initialFiles={[app.logo]}
        />
      </Grid>

  </Grid>
  </div>
    );
  }



  function AppColors() {
    let [myColor, pickedColor] = useState('');
    const [background, setbackground] = useState({hex: '#3174ff'});
    const [TheColor, setTheColor] = useState("#3174ff");
    const dispatch = useDispatch();
    const app = useSelector(state => state.app.app);
    
    const setColorCode = (color)=> {
      setbackground(color);
      setTheColor(color.hex);
      dispatch(setApp("color" ,background.hex));
      console.log("the color be", color);
    }
    return (
        <div>
      <Typography variant="body2" color="textSecondary" align="center">
      Company theme color      
      </Typography>
      <Grid container spacing={0}>
{/* http://casesandberg.github.io/react-color/#usage-install */}
      <Grid item xs={12} align="center">
      
      <Typography variant="body2" color="textSecondary" align="center">      
          {background.hex}
      </Typography>

      <SketchPicker  
      onChangeComplete={ setColorCode }
      color={app.color} 
      />
      </Grid>
      {/* this.state.data */}
      
      <Grid item xs={3} style={{backgroundColor: app.color || "#3174ff"}}>
       {background.hex}
      </Grid>
  </Grid>
  </div>
    );
  }

  function Confirm() {
    return (
        <div>

        </div>
    )
}


function getSteps() {
  return ['App Details*', 'Business Details*', 'Company Colors' , 'Company Logo', 'Settlement Details*'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AppDetails/>;
    case 1:
      return <AppSettings/>;
    case 2:
      return <AppColors/>;
      case 3:
      return <AppLogo/>;
      case 4:
        return <AppSettlement/>;
    default:
      return 'Unknown step';
  }
}

export default function RegisterApp(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const dispatch = useDispatch();
  const app = useSelector(state => state.app.app);
  const loading = useSelector(state => state.app.loading);
  const [disabled, setdisabled] = useState(true);

  // 

  const handleNext = (activeStep) => {
    if(activeStep ===  4){
      dispatch(registerApp(app, props.history ))
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
  };

  const Savedata = (data) => {
    alert("done");
  };

  //Savedata

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const Checkdata = (step)=> {
    switch (step) {
      case 0:
            if(app.name.length > 0){
              return setdisabled(true)
            }else{
              return false
            }
      case 1:
        return {

        }
      case 2:
        return {
          
        }
        case 3:
        return {

        }
        case 4:
          return {
            
          }
      default:
        return 'Unknown step';
    }

  }

  return (
    <div className={classes.root}>
        <Grid container style={{width: '100%'}}>
            <Grid item xs={8}>
            <Stepper activeStep={activeStep} orientation="vertical" >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel style={{ color: "#3174ff" }}>{label}</StepLabel>
            <StepContent>
             {getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                    
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {activeStep === 2 || activeStep === 3 ? 
                        <Button
                        onClick={handleNext}
                        className={classes.button}
                      >
                        Skip 
                      </Button>
                  
                      : 
                      null
                  }
                  
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#3174ff", color: "#ffffff" }}
                   
                    onClick={ e => ( handleNext (activeStep))}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          {
            loading ? 
            <PropagateLoader style={{ color: "#3174ff" , marginLeft: "50px"}}/> 
            :
            <div>
           <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button} variant="contained" style={{ color: "#3174ff" }} disabled>
            View App
          </Button>
            </div>
          }
        </Paper>
      )}
            </Grid>

            <Grid item xs={4}>
            <img src={wallet}/>
            </Grid>

        </Grid>
     
    </div>
  );
}