import { call, put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { getPaymentSuccess, getPaymentFailed} from "./actions";
import {getPayment } from "./api";


export function* getPaymentSaga(action) {
    try {
      const res = yield call(getPayment,action.data);
      console.log("get payment user console",res.data.data);
      yield put(getPaymentSuccess(res.data.data));
    } catch (err) {
      yield put(getPaymentFailed(err));
      console.log("Error of the get payment  add", err.response.data.message);
      toast("Connection Error Invoice");
    //   alert("failed");
    }
  }

  
  
