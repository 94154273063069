import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  GET_APPLICATION,GET_APPLICATION_FAILED,GET_APPLICATION_SUCCESS
  } from "./actionTypes";




  export const getApplications = () => {
    return {
      type: GET_APPLICATION,
    };
  };

  
  export const getApplicationsSuccess = (response) => {
    return {
      type: GET_APPLICATION_SUCCESS,
      response
    };
  };

  export const getApplicationsFailed = (response) => {
    return {
      type: GET_APPLICATION_FAILED,
      response
    };
  };
 
  export const PaymentIntent = (data ,id , secret) => {
    return {
      type: CREATE_PAYMENT,
      data,
      id,
      secret
    };
  };

  export const PaymentIntentSuccess = (response) => {
    return {
      type: CREATE_PAYMENT_SUCCESS,
      response
    };
  };

  export const PaymentIntentFailed = (response) => {
    return {
      type: CREATE_PAYMENT_FAILED,
      response
    };
  };