import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AppBar, Button, Chip, Dialog, Grid, MenuItem, Paper, Select, Slide, Toolbar, Tooltip } from '@material-ui/core';
import { Autorenew, Settings, Visibility } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import RegisterApp from '../components/RegisterApp';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import SyncLoader from "react-spinners/SyncLoader";
import { getTransactions } from '../payments/redux/actions';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    // paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#3174ff",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectApp(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const [page, setThePage] = React.useState(1);

  const [open, setOpen] = React.useState(true);
  const [opend, setOpenDialogue] = React.useState(false);
  const loading = useSelector(state => state.transactions.loading);
  const transactions = useSelector(state => state.transactions.transactions);
  const error = useSelector(state => state.transactions.error);

  const handleClickOpen = () => {
    setOpenDialogue(true);
  };

  const handleClose = () => {
    setOpenDialogue(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
   
    dispatch(getTransactions(page));
    }, []);

    var su= false;

    //columns   

    const columns = [
      {
        name: "key",
        label: "#ID",
        options: {
         filter: false,
         sort: true,
        }
       },
      {
       name: "createdAt",
       label: "Date",
       options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value !== null ?
          <small>{moment(value).format("MMM Do YY")}</small> 
          : <h1></h1>
         )
       }
      },
      {
        name: "payload",
        label: "First Name",
        options: {
         filter: false,
         sort: true,
         customBodyRender: (value, tableMeta, updateValue) => (
           value !== null ?
           (value.first_name)
           : <h1></h1>
          )
        }
       },

       {
        name: "payload",
        label: "Second Name",
        options: {
         filter: false,
         sort: true,
         customBodyRender: (value, tableMeta, updateValue) => (
           value !== null ?
           (value.last_name)
           : <h1></h1>
          )
        }
       },

       {
        name: "provider",
        label: "Payment Mode",
        options: {
         filter: true,
         sort: true,
        //  customBodyRender: (value, tableMeta, updateValue) => (
        //    value !== null ?
        //    (value.town)
        //    : <h1></h1>
        //   )
        }
       },
       {
        name: "amount",
        label: "Amount",
        options: {
         filter: false,
         sort: true,
        }
       },
     
       {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => (
            value ? 
            <Chip label={value === "PENDING" ? 'pending' : value === "COMPLETED" ? 'success' : 'failed' } style={{backgroundColor: value === "COMPLETED" && 'green',color: value === "COMPLETED" && '#ffffff'}}/>
           :
           <Chip label={"Unknown"} />
          ),
          filter: true,
          // customFilterListOptions: { render: v => `Status: ${v}` },
          // filterOptions: {
          //   names: ['created', 'pending', 'failedIprs', 'None'],
          //   label: ["moja","moja","moja","moja"]
      
          // },
          sort: true,
        }
       }
     ];

     const options = {
      filterType: 'checkbox',

      onTableChange: (action, tableState) => {
        console.log(action, tableState);
       
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want
        
        switch (action) {
          case 'changePage':
            // setThePage(tableState.page);
            // dispatch(getTransactions(tableState.page));
            // alert(tableState.page);
            break;
            case 'search':
            // setpages(tableState.page);
            // alert("oya");
            // dispatch(getBankCustomers(su ? "all": bankId , tableState.page))
            break;
          case 'sort':
            // dispatch(getBankCustomers(su ? "all": bankId, tableState.page));
            break;
          default:
            console.log('action not handled.'+ action);
        }
      },
      print: 'false',
      selectableRows: false,
      downloadOptions: {filename: 'CustomersDataLittle.csv', separator: ',',
      filterOptions:{useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true}},
      elevation: 7,
      fixedHeader: true,
    };

  return (
    <>
    <Grid container>
      <Grid item xs='12'>
        {/* Little pay select app before proceed */}
      <Paper style={{width: '100%' , height: '50px'}}>
          <Select
          >
            <MenuItem>dgvdigdig</MenuItem>
            
          </Select>
       </Paper>
      </Grid>
    </Grid>
   
    {/* <MUIDataTable
    
      title={!loading ?  
      <Button
        variant="text"
        color="primary"
         onClick={()=> dispatch(getTransactions(page))}
        className={classes.button}
        startIcon={<Autorenew />}
      >
        Transactions 
      </Button> :
      <SyncLoader  color={"#536DFE"} size={5} />}
      data={transactions}
      columns={su ? columns.filter(data => !data.isBank) : columns.filter(data => !data.su)}
      options={{...options,  textLabels: {
        body: {
          noMatch: 'No records found: ' + error
        },
      }
    }
  }
    /> */}
  </>
  );
}