import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import logo from "../images/logo.png";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from './redux/actions';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import HashLoader from "react-spinners/HashLoader";
import login from "../images/login.png"

//https://static.3cs.lk/2020-redesign/2020/12/1aaa3786-3cs_ecommerce_banner-1.jpg




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://little.bz/">
         Little.bz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${login})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const theUser = useSelector(state => state.login.userData);
  const [fullName, setFullName] = useState("");
  const [confirmPassword, setRepeatPass] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, seterrorEmail] = useState(false);
  const [errorPassword, seterrorPassword] = useState(false);
  const [passwordMessage, setErrorMessage] = useState("Password");
  const [nameError, setnameError] = useState(false);
  const [nameErrorMessage, setnameErrorMessage] = useState("Full Name");
  const [emailLabel, setEmailLabel] = useState("Email");
  const [errorRepeatMessage, seterrorMessageRepeat] = useState("Confirm Password");
  const [errorRepeat, setErrorRepeat] = useState(false);
  const loading = useSelector(state => state.login.loading);


 const setTheName = (value) => {
   if (value.length <= 4){
   setFullName(value);
   setnameError(true);
   setnameErrorMessage("The Name is too short");
   }else{
    setFullName(value);
    setnameError(false);
    setnameErrorMessage("Full Name");

   }
 }
//VALIDATE PASSWORD

const validateConfirmPassword = (password, value) => {
  if(validator.equals(password , value)){
    setRepeatPass(value);
    setErrorRepeat(false);
    seterrorMessageRepeat("Confirm Password");

  }else{
    setRepeatPass(value);
    setErrorRepeat(true);
    seterrorMessageRepeat("Password does not match");
  }
}

const validateStrongPassword = (value) => {
  
  if (validator.isStrongPassword(value, {
    minLength: 8, minLowercase: 1,
    minUppercase: 1, minNumbers: 1, minSymbols: 1
  })) {
    setPassword(value);
    seterrorPassword(false);
    setErrorMessage('Password');
  } else {
    setPassword(value);
    setErrorMessage('Password should be more than 8 characters, at least 1 lowercase and uppercase letters, at least 1 number and 1 symbol');
    seterrorPassword(true);
  }
}

const validateEmail = (value) => {
  if(validator.isEmail(value)){
    setEmail(value);
    seterrorEmail(false);
    setEmailLabel("Email");
  }else{
    seterrorEmail(true);
    setEmail(value);
    setEmailLabel("Incorrect Email");
  }
}




  const dispatch = useDispatch();

  return (
    <Grid container component="main" className={classes.root}>
      <ToastContainer />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
         
            <img src={logo} style={{height: "100px"}}/>

          <Typography  color="primary">
              Login 
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              onChange={e => validateEmail(e.target.value)}
              required
              fullWidth
              error={errorEmail}
              id="email"
              label={emailLabel}
              name="email"
              autoComplete="email"
              autoFocus
            />

            <TextField
              variant="outlined"
              margin="normal"
              onChange={e => setPassword(e.target.value)}
              required
              error={errorPassword}
              fullWidth
              name="password"
              label={passwordMessage}
              type="password"
              id="password"
            />
            {/* <TextField
              variant="outlined"
              margin="normal"
              error={errorRepeat}
              required
              fullWidth
              onChange={e => validateConfirmPassword(password , e.target.value)}
              name="password"
              label={errorRepeatMessage}
              type="password"
              id="password"
              autoComplete="current-password"
            /> */}
            <div className={classes.formButtons}>
           {loading ? (
                  <HashLoader color={"#536DFE"}/>
                  ) : (
            <Button
              fullWidth
              variant="contained"
              // disabled={nameError || errorEmail || errorPassword || errorRepeat }
              color="primary"
              onClick={e => (dispatch(loginUser({
                "email": email,
                "password": password
              },
              props.history
              )))
            }
              className={classes.submit}
            >
              Log In
            </Button>
                  )
          }
          </div>
            <Grid container>
              <Grid item>
                <NavLink to="/signup" variant="body2">
                  {"Have have an account? Sign Up"}
                </NavLink>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}