
import {
  LOGIN_USER,LOGIN_USER_FAILED,LOGIN_USER_SUCCESS
 } from "./actionTypes";
    

//initial state
    const InitialState = {
      loading: false,
      error: null,
      success: false,
    };
    
    export default function loginReducer (state = InitialState, action) {
      
      switch (action.type) {
        //create agents

          case LOGIN_USER:
            return {
              ...state,
              loading: true,
              error: null
            };

            case LOGIN_USER_SUCCESS:
              return {
                ...state,
                loading: false,
                success: true,
                error: null
              };
              case LOGIN_USER_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                error: action.response
              };
        default:
          return state;
      }
    }
    





// registerReducer