import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title> {props.title}</Title>
      <Typography >
        Ksh. {props.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
      </Typography>
      {/* <Typography color="textSecondary" className={classes.depositContext}>
        on
      </Typography> */}
      
    </React.Fragment>
  );
}

