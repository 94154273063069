import {

  GET_TRANSACTIONS,GET_TRANSACTIONS_FAILED,GET_TRANSACTIONS_SUCCESS
  } from "./actionTypes";

    

//initial state
    const InitialState = {
      transactions: [],
      loading: false,
      error: null,
      success: false,
      transactionId: ""
    };
    
    export default function getTransactionsReducer (state = InitialState, action) {
      
      switch (action.type) {
        //create agents

          case GET_TRANSACTIONS:
            return {
              ...state,
              loading: true,
              error: null
            };

            case GET_TRANSACTIONS_SUCCESS:
              return {
                ...state,
                transactions: action.response,
                loading: false,
                success: true,
                error: null
              };
              case GET_TRANSACTIONS_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                error: action.response
              };

            
              
        default:
          return state;
      }
    }
    





// registerReducer