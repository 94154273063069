import axios from 'axios';
import { endPoint } from '../../redux/rootApi';


//loans

export async function registerCall(data) {
  return await axios.post(`${endPoint}auth/register`, 
    data
  )
}
