export const GET_PAYMENT = "GET_PAYMENT";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_FAILED = "GET_PAYMENT_FAILED";








