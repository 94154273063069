import axios from 'axios';
import { endPoint } from '../../redux/rootApi';


//loans

export async function loginCall(data) {
  return await axios.post(`${endPoint}auth/login`, 
    data
  )
}
