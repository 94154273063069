import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getPayment } from './redux/actions';
import HashLoader from "react-spinners/HashLoader";
import { Grid } from '@material-ui/core';
import secure from "../images/back.png";



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://little.bz">
        Little.bz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '100vw',
      height: '970px',
      marginLeft: '0',
      marginRight: '0',
    },
  },
  paper: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    // padding: theme.spacing(2),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   marginTop: theme.spacing(6),
    //   marginBottom: theme.spacing(6),
    //   padding: theme.spacing(3),
    // },
    height: '100vh'
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [ 'Details', 'Payment', 'Confirmation'];



export default function Invoice() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useDispatch();
  const paymentData = useSelector(state => state.getpayment.appData);
  const loading = useSelector(state => state.getpayment.loading);


  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Review data={paymentData}/>;
      case 1:
        return <PaymentForm />;
      case 2:
        return <Review data={paymentData}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const { id } = useParams();
  useEffect(() => {
    //take id and get payment details
    dispatch(getPayment(id))
    }, []);

  return (
  
    (!paymentData ? <div style={{ 
      position: 'absolute',
      width: '100px',
      height: '50px',
      top: '50%',
      left: '50%',
      marginLeft: '-50px', 
      marginTop: '-25px' 
      }}>
           <HashLoader color={"#536DFE"}/>
      </div> :
      <div>
      <CssBaseline />
  
      <main >
        
        <Paper className={classes.paper}>
        <Grid container  spacing={6}>
          <Grid item xs={7} style={{padding: '85px',backgroundImage: `url(${"https://www.nainafurniture.com/wp-content/uploads/2019/10/interior-designs-hd-pics-home-furniture-design-kitchenagenda-com-5b63b6059c484.jpg"})`, backgroundSize: 'cover', height: "650px", backgroundColor: paymentData.token.app.settings.color||"#3174ff"}} >
            <div>

            
            {/* <img src={"https://www.pngkey.com/png/full/203-2037163_for-further-research-check-out-other-reported-vulnerabilities.png"} style={{height: '50%', marginTop:"40px"}}/> */}
            {/* Secure Payment */}
            <img src={paymentData.token.app.logo} style={{marginBottom: "40px"}} />
            <Typography component="h1" variant="h4" align="left" style={{color: "#ffffff"}} >
             {paymentData.token.app.settings.business_name}
            </Typography>

            <Typography component="h1" variant="subtitle2" align="left" style={{color: "#ffffff"}}>
             {paymentData.token.app.description}
            </Typography>
            </div>

          </Grid>

          <Grid item xs={5} style={{padding: '85px'}}>
          {/* <Typography component="h1" variant="h4" align="center">
            {paymentData.token.app.settings.business_name}
          </Typography> */}
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <center>
                <img src="https://i.dlpng.com/static/png/6456086_preview.png" style={{height: "130px"}}/>
                </center>
                <Typography variant="h5" gutterBottom style={{marginBottom: "10px"}}>
                  Thank you for your payment.
                </Typography>
                <Typography variant="subtitle1">
                  Your payment number is #2001539. We have emailed your payment confirmation
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button 
                    onClick={handleBack} 
                    className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    fullWidth
                    style={{ backgroundColor: paymentData.token.app.settings.color||"#3174ff" }}
                    
                  >
                    {activeStep === steps.length - 1 ? 'Complete Payment' : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
          </Grid>
        </Grid>
        </Paper>
        <Copyright />
      </main>
    </div>
      )
   
  );
}