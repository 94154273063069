import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
// import post1 from './blog-post.1.md';
// import post2 from './blog-post.2.md';
// import post3 from './blog-post.3.md';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    marginBottom: "50px"
  },
}));

const sections = [
  { title: 'Home', url: '#' },
  { title: 'Solutions', url: '#' },
  { title: 'FAQs', url: '#' },
  { title: 'Integration', url: '#' },
  { title: 'News', url: '#' },
  { title: 'Contact us', url: '#' },
];

const mainFeaturedPost = {
  title: 'Accept online Payments Within Your Business with Little Pay',
  description:
    "Whether you’re looking for a complete payments solution or want to add Little-Pay as an extra payment method to your existing website, we have a smart solution that’s right for your business. what's most interesting in this post's contents.",
  image: 'https://media.istockphoto.com/photos/woman-using-mobile-smart-phone-in-the-night-light-colorful-background-picture-id1165052026?k=6&m=1165052026&s=612x612&w=0&h=G-dXT4A6aDBfabXbjlM-UuA9L6fgfB91d91VahLvN0A=',
  imgText: 'main image description',
  linkText: 'Get Started Today',
};

const featuredPosts = [
  {
    title: 'On your website',
    date: '',
    description:
      'Accept M-Pesa and card payments on your website, from using checkout buttons to our fully customized online solutions.',
    image: 'https://www.milesweb.in/blog/wp-content/uploads/2015/06/top-10-online-payment-gateways-india.png',
    imageText: 'Get paid on your website ',
  },
  {
    title: 'With online invoicing',
    date: '',
    description:
      'Create and send customized invoices using our free templates. Customers can pay easily in just a few clicks. Accept M-Pesa and card payments on your website',
    image: 'https://project-management.com/wp-content/uploads/2019/03/online-invoicing.jpg',
    imageText: 'Get Started Today',
  },
];

// const posts = [post1, post2, post3];

// const sidebar = {
//   title: 'About',
//   description:
//     'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
//   archives: [
//     { title: 'March 2020', url: '#' },
//     { title: 'February 2020', url: '#' },
//     { title: 'January 2020', url: '#' },
//     { title: 'November 1999', url: '#' },
//     { title: 'October 1999', url: '#' },
//     { title: 'September 1999', url: '#' },
//     { title: 'August 1999', url: '#' },
//     { title: 'July 1999', url: '#' },
//     { title: 'June 1999', url: '#' },
//     { title: 'May 1999', url: '#' },
//     { title: 'April 1999', url: '#' },
//   ],
//   social: [
//     { name: 'GitHub', icon: GitHubIcon },
//     { name: 'Twitter', icon: TwitterIcon },
//     { name: 'Facebook', icon: FacebookIcon },
//   ],
// };

export default function Landing(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="" sections={sections} history={props.history}/>
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} className={classes.mainGrid}>
            {/* <Main title="From the firehose" posts={posts} /> */}
            {/* <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            /> */}
          </Grid>
        </main>
      </Container>
      <Footer title="Little" description="Experience the safe & friendly, affordability and convenience with Little. " style={{marginTop:"50px"}}/>
    </React.Fragment>
  );
}

