import {
  GET_PAYMENT,GET_PAYMENT_FAILED,GET_PAYMENT_SUCCESS
  } from "./actionTypes";


  export const getPayment = (data) => {
    return {
      type: GET_PAYMENT,
      data
    };
  };

  
  export const getPaymentSuccess = (response) => {
    return {
      type: GET_PAYMENT_SUCCESS,
      response
    };
  };

  export const getPaymentFailed = (response) => {
    return {
      type: GET_PAYMENT_FAILED,
      response
    };
  };
 
 