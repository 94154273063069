import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import { Button, Dialog, Tab, Tabs } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Slide from '@material-ui/core/Slide';
import RegisterApp from "../components/RegisterApp"
import { useDispatch, useSelector } from 'react-redux';
import { getApplications } from '../applications/redux/actions';
import { getTransactions } from '../payments/redux/actions';
import axios from 'axios';
import { endPoint } from '../../redux/rootApi';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.little.bz/">
        Little.bz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    color: 'secondary',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  appBard: {
    position: 'relative',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dashboard(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [opend, setOpenDialogue] = React.useState(false);
  const dispatch = useDispatch();
  const applications = useSelector(state => state.applications.applications);
  const [value, setValue] = React.useState(0);

  useEffect(() => {

    dispatch(getApplications())

    axios.get(`${endPoint
    }apps`,
    {
      headers: {
        'Authorization' : `Bearer ${localStorage.getItem("timestamp")}`,
        'Content-Type': 'application/json',
      }
    }
    )
      .then(res => {
        console.log("gravity", res.data.data[0].id)
        dispatch(getTransactions(res.data.data[0].id));

      })
    }, []);

    const transactions = useSelector(state => state.transactions.transactions);

    const few_transactions = transactions.slice(0, 5);

    let total_transactions = transactions.filter(data => data.status ==="COMPLETED" ).reduce((a, b) => +a + +b.amount, 0);
    let total_transactions_cards_little = transactions.filter(data => data.status ==="COMPLETED" && data.provider === "CARDS").reduce((a, b) => +a + +b.amount, 0);

    let total_transactions_cards = transactions.filter(data => data.status ==="COMPLETED" && data.provider === "CARDS3DS").reduce((a, b) => +a + +b.amount, 0);
    let total_transactions_mpesa = transactions.filter(data => data.status ==="COMPLETED" && data.provider === "MPESA").reduce((a, b) => +a + +b.amount, 0);

  const handleDrawerOpen = () => {
    setOpen(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(getApplications());
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const handleClickOpen = () => {
    setOpenDialogue(true);
  };

  const handleClose = () => {
    setOpenDialogue(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let newId = applications[newValue].id
    // dispatch(getTransactions(newId));

  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
      <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>

          <Grid item xs={12} md={8} lg={9}>
          <Button
              type="submit"
              variant="contained"
              color="disabled"
              onClick={handleClickOpen}
            >
              + Create Application
            </Button>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {
            applications.map((data) => (
              <Tab label={data.name}  />
            ))
            }
        </Tabs>
            <Dialog fullScreen open={opend} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBard}  style={{ backgroundColor: "#3174ff" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              New Application
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{padding: '50px'}}>
          <RegisterApp history={props.history}/>
        </div>

      </Dialog>
          </Grid>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title= "Mpesa" total={total_transactions_mpesa}/>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title = "Cards_ABSA" total={total_transactions_cards}/>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title = "Cards_LITTLE" total={total_transactions_cards_little}/>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits title='Total' total={total_transactions}/>
              </Paper>
            </Grid>

            {/* Recent Orders */}

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders payments={few_transactions}/>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </React.Fragment>
  );
}