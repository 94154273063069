import axios from 'axios';
import { endPoint } from '../../../redux/rootApi';
import {decode as base64_decode, encode as base64_encode} from 'base-64';


//apps

export async function getApplicationCall() {
  return await axios.get(`${endPoint}apps`, 
  {
    headers: {
      'Authorization' : `Bearer ${localStorage.getItem("timestamp")}`,
      'Content-Type': 'application/json',
    }
  }
  )
}

//payment intent

export async function payCall(data,id,secret) {
  let encoded = base64_encode(`${id}:${secret}`);
  // console.log("Happy hunting " ,`${id}:${secret}` , encoded);

  return await axios.post(`${endPoint}api/payments/${data}/pay`, {
    "amount": "300",
    "currency": "KES",
    "key" : data,
    "description": "This is a test payment",
    "callbackUrl": "https://wallet-delta.vercel.app",
    "expiresAt": 2,
    "payload": {
      "user": "FirstName SecondName",
      "payment": "Test Application Payment",
      "color" : "#3174ff"
    }
  },
  {
    headers: {
      'Authorization' : `Basic ${encoded}`,
      'Content-Type': 'application/json',
    }
  }
  )
}

