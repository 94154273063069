import {
   REGISTER_APP,SET_APP,REGISTER_APP_SUCCESS,REGISTER_APP_FAILED
  } from "./actionTypes";


//users actions
export const setApp = (field , data) => {
    return {
      type: SET_APP,
      data,
      field
    };
  };


  export const registerApp = (data, history) => {
    return {
      type: REGISTER_APP,
      data,
      history
    };
  };

  
  export const registerAppSuccess = (response) => {
    return {
      type: REGISTER_APP_SUCCESS,
      response
    };
  };

  export const registerAppFailed = (response) => {
    return {
      type: REGISTER_APP_FAILED,
      response
    };
  };