export const GET_APPLICATION = "GET_APPLICATION";
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
export const GET_APPLICATION_FAILED = "GET_APPLICATION_FAILED";

// payment intent

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILED = "CREATE_PAYMENT_FAILED";






