import { call, put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { registerUserFailed, registerUserSuccess } from "./actions";
import { registerCall } from "./api";



export function* AddUserSaga(action) {
    try {
      const res = yield call(registerCall, action.data);
      console.log("add user console",res);
      yield put(registerUserSuccess("res.data"));
      toast("User has been registered");
      action.history.push('/login');
      //alert("success");
    } catch (err) {
      yield put(registerUserFailed(err));
      console.log("Error of the users add", err);
      toast("Invalid Data");
    //   alert("failed");
    }
  }
  