import {
   LOGIN_USER,LOGIN_USER_FAILED,LOGIN_USER_SUCCESS
  } from "./actionTypes";


// 

  export const loginUser = (data, history) => {
    return {
      type: LOGIN_USER,
      data,
      history
    };
  };

  
  export const loginUserSuccess = (response) => {
    return {
      type: LOGIN_USER_SUCCESS,
      response
    };
  };

  export const loginUserFailed = (response) => {
    return {
      type: LOGIN_USER_FAILED,
      response
    };
  };