import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import logo from "../images/logo.png"
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        {/* <Button size="small">Little Pay</Button> */}
        <img src={logo} style={{height: '80px', padding: "10px"}}/>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          {title}
        </Typography>
        {/* <IconButton>
          <SearchIcon />
        </IconButton> */}
        {/* <NavLink  to='/signup'> */}
        <Button variant="outlined" size="small" style={{marginRight: "20px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "15px", paddingRight: "15px", borderRadius: "50px"}}  onClick={e => ( props.history.push("/signup"))}>
          Get Started
        </Button>
        {/* </NavLink> */}
        {/* <NavLink  to='/login' > */}
        <Button variant="contained" size="small" color="primary" onClick={e => ( props.history.push("/login"))} style={{marginRight: "20px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "15px", paddingRight: "15px", borderRadius: "50px"}}>
          Login
        </Button>
        {/* </NavLink> */}
       
      </Toolbar>
      {/* <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        {sections.map((section) => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar> */}
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};