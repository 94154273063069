import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AppBar, Button, Chip, Dialog, Grid, Slide, Toolbar, Tooltip } from '@material-ui/core';
import { Settings, Visibility } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import RegisterApp from '../components/RegisterApp';
import { useDispatch, useSelector } from 'react-redux';
import { getApplications, PaymentIntent } from './redux/actions';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 100,
    // paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#3174ff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Applications(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [opend, setOpenDialogue] = React.useState(false);
  const applications = useSelector(state => state.applications.applications);
  const handleClickOpen = () => {
    setOpenDialogue(true);
  };

  const handleClose = () => {
    setOpenDialogue(false);
    dispatch(getApplications());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // if(!fetched){
    //   dispatch(getApplications(su ? "all": bankId, '0'))
    // }
    dispatch(getApplications())
    }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>

          <Button
              type="submit"
              variant="contained"
              color="disabled"
              onClick={handleClickOpen}
            >
              + Create Application    
            </Button>
            <Dialog fullScreen open={opend} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBard}  style={{ backgroundColor: "#3174ff" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              New Application
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save  
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{padding: '50px'}}>
          <RegisterApp history={props.history}/>
        </div>
      </Dialog>
      </Grid>
      {
        applications.map(app => (     
          <Grid item xs={4}>
          <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" style={{backgroundColor: app.settings.color || '#3174ff'}}>
                {app.name.charAt(0)}
              </Avatar>
            }
            action={
              <Tooltip title="Sandbox">
                    <Chip size="small" label="Sandbox" style={{margin: "10px"}}/>
              </Tooltip>
            }
            title={app.name}
            subheader={moment(app.createdAt).format("MMM Do YY")}
          />
          <CardMedia
            className={classes.media}
            image="https://lh3.googleusercontent.com/jJ1-7npEf-jhIm7TfFW5FfnI9IwD4t_Gd8T61I8ePKC6AUHNCcwJD0PI3eybyYUiYt2HQT3baQ74eGVmJ_X4cApa09ax"
            title="wfefefefefefefefefe"
          />
          <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              <b>App Id:</b> {app.id}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              <b>Client Id:</b> {app.clientId}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              <b>Client Secret:</b> {app.clientSecret}
            </Typography>
          </CardContent>

          <CardActions disableSpacing>
          {/* <Tooltip title="Settings">
          <IconButton aria-label="add to favorites" disabled>
              <Settings />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Preview Application">
          {/* <NavLink to="/invoice" target="_blank" rel="noopener noreferrer" > */}
          <IconButton aria-label="share" onClick={e  => dispatch(PaymentIntent(app.tokens[0].id,app.clientId,app.clientSecret))}>
              <Visibility />
            </IconButton>
          {/* </NavLink> */}
            </Tooltip>
          </CardActions>
        </Card>
          </Grid>
        ))
      }
    </Grid>
  );
}