import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import Title from './Title';
import moment from 'moment';
import { getTransactions } from '../payments/redux/actions';
import { useDispatch, useSelector } from 'react-redux';



export default function Chart() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const transactions = useSelector(state => state.transactions.transactions);

  useEffect(() => {
   
    dispatch(getTransactions("iidid"));
    }, []);


  //use state

  // Generate chart Data
function createData(month, month_number) {
  //  loans.reduce((a, b) => +a + +b.LoanAmount, 0)
  var complete_transactions = transactions.filter(item => item.status  === "COMPLETED");
 let total = complete_transactions.filter((item) => {
    // item.status === "COMPLETED";
    var date = new Date(item.createdAt);
    var tdate = date.getDate(); //returns date (1 to 31) you can getUTCDate() for UTC date
    var month = date.getMonth() + 1; // returns 1 less than month count since it starts from 0
    var year = date.getFullYear();

    var today = new Date();
    var mdate = today.getDate(); //returns date (1 to 31) you can getUTCDate() for UTC date
    var mmonth = today.getMonth() + 1; // returns 1 less than month count since it starts from 0
    var myear = today.getFullYear();

    return moment(`${year}/${month}/${tdate}`).isSame(
      moment(`${myear}/${month_number}/${mdate}`).format("L"),
      "month"
    );
  }).reduce((a, b) => +a + +b.amount, 0);
  console.log(total);
  
  return { month, total };
}

const data = [
  createData('Jan', 1),
  createData('Feb', 2),
  createData('March', 3),
  createData('April', 4),
  createData('May', 5),
  createData('Jun', 6),
  createData('July', 7),
  createData('Aug', 8),
  createData('Sept', 9),
  createData('Oct', 10),
  createData('Nov', 11),
  createData('Dec', 12),
];

 console.log("the data can be fetched", data);


  return (
    <React.Fragment>
      <Title>Transactions</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >

          <XAxis dataKey="month" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Total (Ksh)
            </Label>
          </YAxis>
          <Tooltip />
          <Line type="monotone" dataKey="total" stroke={theme.palette.primary.main} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}