import axios from 'axios';
import { endPoint } from '../../../redux/rootApi';
import {decode as base64_decode, encode as base64_encode} from 'base-64';


//apps
//bd2f5fbb-6314-4c49-a6a4-bec52b915007


export async function getTransactionsCall(id) {
  // let the_page = page ? +page : 1;

  // console.log("the page is======================", page);

  return await axios.get(`${endPoint}apps/${id}/payments?page=1&size=100000`, 
  {
    headers: {
      'Authorization' : `Bearer ${localStorage.getItem("timestamp")}`,
      'Content-Type': 'application/json',
    }
  }
  )
}

//payment intent
