import {
  REGISTER_APP,SET_APP,REGISTER_APP_SUCCESS,REGISTER_APP_FAILED
 } from "./actionTypes";

    

//initial state
    const InitialState = {
      app: {

      },
      loading: false,
      error: null,
      success: false,
    };
    
    export default function appReducer (state = InitialState, action) {
      
      switch (action.type) {
        //create agents
      
        case SET_APP:
          state.app[action.field] = action.data;
          let updatedApp = state.app;
          return {
            ...state,
            app: updatedApp
          };

          case REGISTER_APP:
            return {
              ...state,
              loading: true,
              error: null
            };

            case REGISTER_APP_SUCCESS:
              return {
                ...state,
                loading: false,
                success: true,
                error: null
              };
              case REGISTER_APP_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                error: action.response
              };
        default:
          return state;
      }
    }
    





// registerReducer