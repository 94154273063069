import { SignalCellularNullOutlined } from "@material-ui/icons";
import {
  GET_PAYMENT,GET_PAYMENT_FAILED,GET_PAYMENT_SUCCESS
  } from "./actionTypes";
    

//initial state
    const InitialState = {
      appData: null,
      loading: false,
      error: null,
      success: false,
    };
    
    export default function getPaymentReducer (state = InitialState, action) {
      
      switch (action.type) {
        //create agents

          case GET_PAYMENT:
            return {
              ...state,
              loading: true,
              error: null
            };

            case GET_PAYMENT_SUCCESS:
              return {
                ...state,
                appData: action.response,
                loading: false,
                success: true,
                error: null
              };
              case GET_PAYMENT_FAILED:
              return {
                ...state,
                loading: false,
                success: false,
                error: action.response
              };

        default:
          return state;
      }
    }
    





// registerReducer