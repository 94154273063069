import { call, put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { registerAppFailed, registerAppSuccess } from "./actions";
import { appsCall } from "./api";

export function* AddAppSaga(action) {
    try {
      const res = yield call(appsCall, action.data);
      console.log("add app console",res);
      yield put(registerAppSuccess(res));
      toast("App has been registered");
      // action.history.push('/apps');
      //alert("success");
    } catch (err) {
      yield put(registerAppFailed(err));
      console.log("Error of the app add", err);
      toast("App not Added, check the details and try again");
    //   alert("failed");
    }
  }
  