import {

  GET_TRANSACTIONS,GET_TRANSACTIONS_FAILED,GET_TRANSACTIONS_SUCCESS
  } from "./actionTypes";




  export const getTransactions = (data) => {
    return {
      type: GET_TRANSACTIONS,
      data
    };
  };

  
  export const getTransactionsSuccess = (response) => {
    return {
      type: GET_TRANSACTIONS_SUCCESS,
      response
    };
  };

  export const getTransactionsFailed = (response) => {
    return {
      type: GET_TRANSACTIONS_FAILED,
      response
    };
  };
 