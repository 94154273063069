import { call, put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { getApplicationsFailed, getApplicationsSuccess ,PaymentIntentFailed} from "./actions";
import { getApplicationCall, payCall } from "./api";
import { endPoint } from '../../../redux/rootApi';




export function* getAppsSaga(action) {
    try {
      const res = yield call(getApplicationCall);
      console.log("get applications user console",res);
      yield put(getApplicationsSuccess(res.data.data));
      // toast("success");
      //alert("success");
    } catch (err) {
      yield put(getApplicationsFailed(err));
      console.log("Error of the users add", err.response.data.message);
      toast("Connection Error");
    //   alert("failed");
    }
  }
  

  //payment intent


  export function* paymentIntentSaga(action) {
    
    try {
      const res = yield call(payCall , action.data, action.id, action.secret);

      console.log("payment intent console",res, action.data);
      window.open(`${"http://localhost:3000/#/invoice/"}${res.data.data.reference}`, '_blank').focus();
      //yield put(getApplicationsSuccess(res.data.data.reference));
      // toast("success");
      //alert("success");
    } catch (err) {
      yield put(PaymentIntentFailed(err));
      console.log("Error of the payment intent add", err.response.data.message);
      toast("Connection error Application");
    //   alert("failed");
    }
  }
  